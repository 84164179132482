<template>
  <div>
    <div class="criteria-container">
      <div @click="toggleCriteria(c)" :class="['criteria', criteriaActive(c) ? 'active-criteria' : 'inactive']" :key="c" v-for="c in criteria">
        {{ c }}
      </div>
    </div>
    <h2>Selected ({{ filteredCafeList.length }})</h2>
    <div class="cafe-container">
      <div class="cafe" :key="cafe.name" v-for="cafe in filteredCafeList">
        {{ cafe.name }}<br />
        <div class="cafe-cats" :key="cafe.name + key" v-for="key in selectedCriteria">
          {{ key }}: {{ cafe[key] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      criteria: [
        'quiet',
        'powerSocket',
        'fancy',
        'goodCoffee',
        'canSitForALongTime',
        'hasManySeats',
        'decaf',
        'goodCake',
        'openOnSundays',
        'longOpeningHours',
        'veganBreakfast',
        'outdoors',
        'indoors'
      ],
      selectedCriteria: [],
      cafeList: [{
        name: "Kafi Hardi",
        quiet: true,
        powerSocket: "unknown",
        fancy: true,
        goodCoffee: true,
        canSitForALongTime: true,
        hasManySeats: true,
        decaf: "unkonwn",
        goodCake: true,
        openOnSundays: true,
        longOpeningHours: false,
        indoors: true,
        outdoors: true
      },{
        name: "Bridge",
        quiet: false,
        powerSocket: true,
        fancy: true,
        goodCoffee: true,
        canSitForALongTime: true,
        hasManySeats: true,
        decaf: true,
        goodCake: false,
        openOnSundays: false,
        longOpeningHours: true,
        indoors: true,
        outdoors: true
      },{
        name: "Paddys",
        quiet: false,
        powerSocket: false,
        fancy: false,
        goodCoffee: false,
        canSitForALongTime: true,
        hasManySeats: "unknown",
        decaf: false,
        goodCake: false,
        openOnSundays: true,
        longOpeningHours: true,
        indoors: true,
        outdoors: true
      },{
        name: "Babu's Bakery",
        quiet: false,
        powerSocket: "maybe",
        fancy: true,
        goodCoffee: true,
        canSitForALongTime: true,
        hasManySeats: false, // always full
        decaf: true,
        goodCake: false,
        openOnSundays: true,
        longOpeningHours: false,
        indoors: true,
        outdoors: true
      },{
        name: "Boréal",
        quiet: true,
        powerSocket: true,
        fancy: false,
        goodCoffee: true,
        canSitForALongTime: true,
        hasManySeats: true,
        decaf: true,
        goodCake: false,
        openOnSundays: true,
        longOpeningHours: false,
        indoors: true,
        outdoors: true
      },
      {
        name: "Mamé",
        quiet: true,
        powerSocket: false,
        fancy: false,
        goodCoffee: true,
        canSitForALongTime: true,
        hasManySeats: true,
        decaf: true,
        goodCake: false,
        openOnSundays: true,
        longOpeningHours: false,
        indoors: true,
        outdoors: true
      },{
        name: "Café Noir",
        quiet: true,
        powerSocket: false,
        fancy: true,
        goodCoffee: true,
        canSitForALongTime: true,
        hasManySeats: false,
        decaf: true,
        goodCake: true, // Banana bread
        openOnSundays: false,
        longOpeningHours: "true, not on saturdays though",
        indoors: true,
        outdoors: true
      },{
        name: "Globus Caffè",
        quiet: false,
        powerSocket: false,
        fancy: true,
        goodCoffee: true,
        canSitForALongTime: true,
        hasManySeats: true,
        decaf: true,
        goodCake: true, // desert
        openOnSundays: true,
        longOpeningHours: true,
        indoors: true,
        outdoors: true
      },{
        name: "Sprössling Café",
        quiet: false,
        powerSocket: false,
        fancy: false,
        goodCoffee: true,
        canSitForALongTime: false,
        hasManySeats: false,
        decaf: true,
        goodCake: true,
        openOnSundays: false,
        longOpeningHours: false,
        veganBreakfast: true,
        indoors: true,
      },{
        name: "Gerold's Garden",
        quiet: false,
        powerSocket: false,
        fancy: true,
        goodCoffee: true,
        canSitForALongTime: true,
        hasManySeats: true,
        decaf: true,
        goodCake: false,
        openOnSundays: true,
        longOpeningHours: true,
        veganBreakfast: false,
        indoors: false,
        outdoors: true
      },{
        name: "Sphères",
        quiet: true,
        powerSocket: false,
        fancy: true,
        goodCoffee: true,
        canSitForALongTime: true,
        hasManySeats: false,
        decaf: true,
        goodCake: false,
        openOnSundays: true,
        longOpeningHours: "not at the weekend, otherwise yes",
        veganBreakfast: "unknown",
        indoors: true,
        outdoors: false
      },{
        name: "Franzos",
        quiet: false,
        powerSocket: false,
        fancy: true,
        goodCoffee: true,
        canSitForALongTime: true,
        hasManySeats: false,
        decaf: false,
        goodCake: false,
        openOnSundays: true,
        longOpeningHours: true,
        veganBreakfast: false,
        indoors: true,
        outdoors: true
      },{
        name: "Joe & the Juice",
        quiet: false,
        powerSocket: false,
        fancy: true,
        goodCoffee: true,
        canSitForALongTime: true,
        hasManySeats: true,
        decaf: false,
        goodCake: false,
        openOnSundays: true,
        longOpeningHours: true,
        veganBreakfast: false,
        indoors: true,
        outdoors: false
      },{
        name: "Enzian",
        quiet: false,
        powerSocket: false,
        fancy: false,
        goodCoffee: false,
        canSitForALongTime: true,
        hasManySeats: true,
        decaf: true,
        goodCake: true,
        openOnSundays: false,
        longOpeningHours: true,
        veganBreakfast: true,
        indoors: true,
        outdoors: true
      },{
        name: "Roots",
        quiet: false,
        powerSocket: false,
        fancy: "kind of",
        goodCoffee: true,
        canSitForALongTime: "sometimes",
        hasManySeats: false,
        decaf: false,
        goodCake: "Banana bread only",
        openOnSundays: true,
        longOpeningHours: false,
        veganBreakfast: true,
        indoors: true,
        outdoors: true
      },{
        name: "Beetnut",
        quiet: true,
        powerSocket: false,
        fancy: true,
        goodCoffee: "dont know",
        canSitForALongTime: true,
        hasManySeats: true,
        decaf: false,
        goodCake: "pancakes and brownie",
        openOnSundays: true,
        longOpeningHours: true,
        veganBreakfast: true,
        indoors: true,
        outdoors: true
      },{
        name: "Grand Café nähe Franzos",
        quiet: false,
        powerSocket: "YES! Look under the seat!",
        fancy: false,
        goodCoffee: true,
        canSitForALongTime: true, // don't know yet
        hasManySeats: true,
        decaf: false,
        goodCake: false,
        openOnSundays: true,
        longOpeningHours: true,
        veganBreakfast: false,
        indoors: true,
        outdoors: true
      },{
        name: "Time",
        quiet: true,
        powerSocket: true,
        fancy: true,
        goodCoffee: false,
        canSitForALongTime: true, // don't know yet
        hasManySeats: true,
        decaf: true,
        goodCake: false,
        openOnSundays: true,
        longOpeningHours: true,
        veganBreakfast: false,
        indoors: true,
        outdoors: false
      },{
        name: "Miyuko",
        quiet: true,
        powerSocket: "I think so",
        fancy: true,
        goodCoffee: true,
        canSitForALongTime: true,
        hasManySeats: false,
        decaf: true,
        goodCake: "amazing ones!",
        openOnSundays: true,
        longOpeningHours: false,
        veganBreakfast: true,
        indoors: true,
        outdoors: true
      },{
        name: "Rio Bar",
        quiet: false,
        powerSocket: false,
        fancy: true,
        goodCoffee: true,
        canSitForALongTime: true,
        hasManySeats: false,
        decaf: true,
        goodCake: false,
        openOnSundays: true,
        longOpeningHours: true,
        veganBreakfast: false,
        indoors: true,
        outdoors: true
      },{
        name: "Vito",
        quiet: false,
        powerSocket: false,
        fancy: true,
        goodCoffee: true,
        canSitForALongTime: "true, but they urge you to order more",
        hasManySeats: true,
        decaf: true,
        goodCake: false,
        openOnSundays: true,
        longOpeningHours: "very long!",
        veganBreakfast: false,
        indoors: true,
        outdoors: true
      },{
        name: "Hiltl",
        quiet: false,
        powerSocket: false,
        fancy: true,
        goodCoffee: true,
        canSitForALongTime: "depends on the time",
        hasManySeats: true,
        decaf: true,
        goodCake: true,
        openOnSundays: true,
        longOpeningHours: true,
        veganBreakfast: true,
        indoors: true,
        outdoors: true
      },{
        name: "Gorilla",
        quiet: true,
        powerSocket: false,
        fancy: true,
        goodCoffee: true,
        canSitForALongTime: "dont know yet",
        hasManySeats: false,
        decaf: true,
        goodCake: false,
        openOnSundays: true,
        longOpeningHours: false,
        veganBreakfast: false,
        indoors: true,
        outdoors: true
      },{
        name: "Odeon",
        quiet: false,
        powerSocket: false,
        fancy: false,
        goodCoffee: true,
        canSitForALongTime: "dont know yet",
        hasManySeats: false,
        decaf: "unknown",
        goodCake: false,
        openOnSundays: true,
        longOpeningHours: true,
        veganBreakfast: false,
        indoors: true,
        outdoors: true
      },{
        name: "Prêt à manger",
        quiet: false,
        powerSocket: "don't know yet",
        fancy: false,
        goodCoffee: true,
        canSitForALongTime: true,
        hasManySeats: true,
        decaf: true,
        goodCake: false,
        openOnSundays: true,
        longOpeningHours: true,
        veganBreakfast: true,
        indoors: true,
        outdoors: true
      }]
    };
  },
  computed: {
    filteredCafeList() {
      const arr = [];
      for(let i = 0; i < this.cafeList.length; i++) {
        if(this.meetsCriteria(this.cafeList[i])) {
          arr.push(this.cafeList[i])
        }
      }
      return arr;
    },
  },
  methods: {
    criteriaActive(criteria) {
      return this.selectedCriteria.includes(criteria);
    },
    toggleCriteria(criteria) {
      if(this.selectedCriteria.includes(criteria)) {
        const index = this.selectedCriteria.findIndex(item => {return item === criteria});
        this.selectedCriteria.splice(index, 1);
      } else {
        this.selectedCriteria.push(criteria)
      }
    },
    meetsCriteria(cafe) {
      if(!this.selectedCriteria.length) {
        return true;
      }
      let meets = true;
      for(let i = 0; i < this.selectedCriteria.length; i++) {
        const c = this.selectedCriteria[i];
        if(!cafe[c]) {
          meets = false;
        }
      }
      return meets;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.cafe-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.criteria-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.criteria {
  margin-right: 5px;
  padding: 5px;
  background-color: #2c3e50;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  pointer: cursor;
  transition: all 300ms ease;
  margin-bottom: 5px;
}
.criteria:hover, .active-criteria {
  background-color: #ff5100;
}
.cafe {
  margin-bottom: 15px;
  margin-right: 20px;
  text-align: left;
  font-weight: bold;
}
.cafe-cats {
  font-size: 0.8rem;
  font-weight: normal;
}
</style>
